.plan-details-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 2px;
  background-color: #cccccc17;

  .plan-banner {
    width: 100%;
    border-radius: 2px 2px 0 0;
    overflow: hidden;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      display: flex;
    }
  }

  .plan-content {
    text-align: start;
    padding: 20px;

    .plan-title {
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 15px;

      @media(max-width: 980px){
        font-size: 20px;
      }
      @media(max-width: 480px){
        font-size: 16px;
      }
    }

    .plan-description {
      font-size: 16px;
      margin-bottom: 20px;

      @media(max-width: 980px){
        font-size: 14px;
      }
      @media(max-width: 480px){
        font-size: 12px;
      }
    }

    .plan-price {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 20px;

      @media(max-width: 980px){
        font-size: 16px;
      }
      @media(max-width: 480px){
        font-size: 14px;
      }
    }

    .back-button {
      color: #2dbebd;
      cursor: pointer;

      &.template-9{
        color: var(--button-color);
      }

      @media(max-width: 480px){
        font-size: 12px;
      }
    }
  }
}