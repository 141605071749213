@import "./Components/Header/scss/header";
@import "./Screens/homepage/scss/features";
@import "./Screens/homepage/scss/homepage";
@import "./Screens/homepage/scss/categories";
@import "./Components/ShowCard/scss/showCard";
@import "./Components/Footer/scss/footer";
@import "./Screens/ShowDetailsScreen/scss/detailsScreen";
@import "./Screens/Accounts/Login/scss/login";
@import "./Screens/Accounts/Register/scss/register";
@import "./Components/CommonPage/scss/commonPage";
@import "./Screens/PaymentStatus/PaymentFailed/scss/paymentFailed";
@import "./Screens/PaymentStatus/PaymentSuccess/scss/paymentSuccess";
@import "./Screens/Accounts/ForgotPassword/scss/forgotPassword";
@import "./Screens/Accounts/ForgotPassword/scss/ResetPassword";
@import "./Components/Header/scss/headerAccounts";
@import "./Components/Modals/scss/OtpModal";
@import "./Components/Modals/scss/countinueWatching";
@import "./Components/Modals/scss/currentPasswordModal";
@import "./Components/Modals/scss/watchWithoutAds";
@import "./Components/Modals/scss/logoutAllModal";
@import "./Components/Modals/scss/cancelSubscriptionModal";
@import "./Screens/LoginRequest/scss/loginRequest";
@import "./Components/Modals/scss/playNextEpisode";
@import "./Screens/Accounts/AccountSettings/scss/accountSetting";
@import "./Screens/Accounts/AccountSettings/scss/BillingDetails";
@import "./Screens/Accounts/AccountSettings/scss/streamingActivity";
@import "./Screens/homepage/scss/live";
@import "./Components/ShowsRow/scss/showsRow";
@import "./Screens/Subscription/scss/subscription";
@import "./Screens/Subscription/scss/SubscriptionTwo";
@import "./Screens/Subscription/scss/payment";
@import "./Screens/Accounts/ChangePassword/scss/changepwd";
@import "./Screens/Search/scss/search";
@import "./Screens/MyList/scss/myList";
@import "./Screens/PrivacyPolicy/scss/privacyPolicy";
@import "./Screens/TermsAndConditions/scss/termsAndConditions";
@import "./Screens/ContactPages/scss/contactUs";
@import "./Screens/ContactPages/scss/contactSupport";
@import "./Screens/About/scss/about";
@import "./Components/Loading/scss/loading";
@import "./Components/NewsCard/NewsCard";
@import "./Screens/NewsSection/scss/LatestNews";
@import "./Screens/NewsSection/scss/newsDetails";
@import "./Screens/Blogs/scss/blogs";
@import "./Screens/LandingScreen/scss/landingScreen";
@import "./Screens/NotFound/scss/nowFound";
@import "./Screens/LinkTvApp/linkTvApp";
@import "./Screens/LivePlayer/scss/livePlayer";
@import "./Components/Modals/scss/podcastModal";
@import "./Screens/AffliatePage/scss/affliatePage";
@import "./Components/OpenApp/scss/openApp";
@import "./Components/Modals/scss/scheduleDetailsModal";
@import "./Screens/LiveChannels/scss/liveChannels";
@import "./Components/Modals/scss/languageModal";
@import "./Screens/EventDetails/scss/eventDetailsScreen";
@import "./Components/Modals/scss/ideaBizPhoneModal";
@import "./Components/TopBanner/topBanner";
@import "./Components/Header/scss/customHeader";
@import "./Components/Modals/scss/loginModal";
@import "./Components/MagazineCard/scss/magazineCard";
@import "./Screens/VideoPlayer/scss/videoPlayer";
@import "./Routes/scss/layoutRoutes";
@import "./Screens/ShowAllEpisodes/scss/showallepisodes";
@import "./Screens/VideoPlayer/Components/scss/autoplay";
@import "./Components/Modals/scss/noScheduleAvailableModal";
@import "./Components/Header/scss/contentProducesBlank";
@import './Components/Modals/scss/socialLoginModal';
@import './Components/TopTrending/Components/scss/top-ten-card';
@import './Components/TopTrending/Components/scss/toptrendingepisode';
@import './Components/TopTrending/Components/scss/sectionslider';
@import './Components/PlaylistCard/scss/playlistCard';
@import './Screens/homepage/scss/redeembanner';
@import './Screens/B2BDetailsScreen/scss/b2bDetailsScreen';
@import './Screens/PlaylistPage/scss/playlist';
@import './Screens/Shorts/scss/shorts';
@import './Screens/Shorts/scss/shortsCard';
@import './Screens/VideoPlayer/Components/scss/commentReply';
@import './Screens/Shorts/ShortsComments/scss/commentRepliesShorts';
@import './Screens/Trailer/scss/trailer';
@import './Components/Modals/scss/trailerModal';
@import './Components/Modals/scss/ratingsAndReviewModal';
@import './Components/NewReleases/scss/newReleases';
@import './Screens/homepage/scss/gaiabanner';
@import './Components/Header/scss/gaiaHeader';
@import './Screens/homepage/scss/gaiaCategories';
@import './Components/ShowCard/scss/gaiaCard';
@import './Screens/homepage/scss/gaiaHomepage';
@import './Components/TopTrending/Components/scss/gaiasectionslider';
@import './Screens/homepage/scss/moreTopics';
@import './Screens/ShowDetailsScreen/scss/gaiaDetailsScreen';
@import './Screens/homepage/scss/gaiaGenres';
@import './Components/Modals/scss/downloadAPKModal';
@import './Screens/Accounts/Checkout/scss/planDetails';
:root{
  --grey-color:#9699a3;
  // --text-hover:#f00; //bounty hunter
  --text-hover:#188fcb; //fish
  --bg-color:#10141f;
  --secondary-color:#188fcb;
  --secondary-bg-color:#1c1f29;
  --overlay:rgba(16,20,31,.64);
  --text-color:#fff;
  --white-color:#fff;
  --border-color:rgba(150,153,163,.16);
  --inner-text-color:#9699a3;
  --success-color:#4BB543;
  --error-color:#E72A31;
  --yellow-color:#f8d406;
  --black-color:#000;
  // --button-hover:#f00; //bounty hunter
  // --button-color:#f00;
  --button-hover:#188fcb; //fish
  --button-color:#188fcb; //fish
  --custom-header-bg-color:#1151d3;
  --custom-top-banner-bg-color:#202f5b;
  --button-text-color:#000;
  --gradient-left-color:#01c599;
  --gradient-right-color:#0388cc;
  --swiper-button-color:rgb(16, 20, 31);
}
.wrapper{
    width: 92%;
    margin: 0 auto;
    @media all and (max-width:980px){
      width: 85%;
    }
}

.swiper-button-next.swiper-button-disabled {
  display: none !important;
}
.swiper-button-prev.swiper-button-disabled {
display: none !important;
}
.swiper-button-next{
  width: 45px !important;

  &::after{
      background: var(--swiper-button-color);
      border-radius: 50%;
      font-size: 16px;
      width: 45px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--inner-text-color);
      @media all and (min-width:320px){
         
        width: 25px;
        height: 25px;
        font-size: 12px !important;
          
      }
      @media all and (min-width:1200px){
         
          font-size: 16px !important;
          width: 45px;
          height: 45px;
      
       }
  }
}
.swiper-button-prev{
  width: 45px !important;
  &::after{
      background: var(--swiper-button-color);
      border-radius: 50%;
      font-size: 16px;
      width: 45px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--inner-text-color);
      @media all and (min-width:320px){
         
        width: 25px;
        height: 25px;
        font-size: 12px !important;
      
      }
      @media all and (min-width:1200px){
         
          font-size: 16px !important;
          width: 45px;
          height: 45px;
      
       }
  }
}

span.error{
	font-size: 14px;
    color: #E72A31;
    display: block;
    margin-top: 10px;
	font-weight: normal !important;
}
div.timelineDate{
  color: var(--button-color);
  font-size: 13px;
  position: relative;
  right: 10px;
}
.css-czcuoc-TimelineDividers {
position: relative !important;
span.currentTime{
  position: absolute;
  bottom: 20px;
  background-color: var(--button-color);
  color: var(--white-color);
  font-size: 12px;
  padding: 3px;
  border-radius: 5px;
  width: 4rem;
  &::before{
    height: 30rem;
    content: " ";
    width: 2px;
    background-color: var(--button-color);
    display: block;
    position: absolute;
    top: 20px;
    bottom: 0;
    left: 50%;

  }
}
}
div#main{
  position: relative;
}

div.customHeader{
  div#main{
    div.header-container{
      position: sticky !important;
    }
  }
}

@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
